import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { PiHandshakeDuotone } from "react-icons/pi";

import rcy_icon from "./../media/img/vista_rcy_logo.png";
import vista_logo from "./../media/img/vista_logo.png";
import info_icon from "./../media/img/vista_info_logo.png";
import eth_icon from "./../media/img/eth-icon.png";
import close_icon from "./../media/img/close_icon.png";
import warn_icon from "./../media/img/warn-icon.png";
import search_icon from "./../media/img/search_icon.png";
import angle_up_icon from "./../media/img/angle_up.png";

import { ReactComponent as BscLogo } from "./../media/img/bscscan-logo.svg";

import { demoNfts } from "../utilities/demo-nfts";
import { demoTokens } from "../utilities/demo-tokens";
import { demoLps } from "../utilities/demo-lp";

import { ToastContainer, toast } from "react-toastify";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import "react-toastify/dist/ReactToastify.css";
import { useTokenBalances, useNftBalances } from "../hooks/useMoralisApi";
import { supportedNetworks } from "../config";
import {
  burnERC1155,
  burnERC721,
  burnTokens,
  formatAddress,
  formatTokenAmount,
  getImageOrBrokenLink,
  lookupEnsByAddress,
} from "../scripts/main";
import { BrowserProvider } from "ethers";

const Home = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [sidelinks, setsidelinks] = useState(true)
  const [appbox, setappbox] = useState(null);
  const [aboutbox, setaboutbox] = useState(true)
  const [shownfts, setshownfts] = useState(true);
  const [showtokens, setshowtokens] = useState(null);
  const [showdomains, setshowdomians] = useState(null);
  const [showlp, setshowlp] = useState(null);
  const [warnmodal, setwarnmodal] = useState(null);

  const [searchAddress, setSearchAddress] = useState("");
  const [ensName, setEnsName] = useState("");
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [activeChain, setActiveChain] = useState(
    supportedNetworks.find((e) => e.chainId === chainId)
  );
  const [chainIdHex, setchainIdHex] = useState(
    activeChain?.hexChainId || "0x1"
  );

  useEffect(() => {
    // Set an interval to update the time every second
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(timer);
  }, []);

  // Format the time as HH:MM:SS
  const formatTime = (date) => {
    let hours = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    let seconds = date.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      console.log(window.innerWidth)
    };

    window.addEventListener('resize', handleResize);
  },[])  

  useEffect(() => {
    if(windowWidth < 500) {
      setsidelinks(null)
    } else {
      setsidelinks(true)
    }
  }, [windowWidth])

  const handleSideLinks = () => {
    if(sidelinks) {
      setsidelinks(null)
    } else {
      setsidelinks(true)
    }
  }

  useEffect(() => {
    const chain = supportedNetworks.find((e) => e.chainId === chainId);
    setActiveChain(chain);
    setchainIdHex(chain?.hexChainId);
  }, [chainId, address]);

  const {
    tokenBalances,
    lpBalances,
    loading: tokenLoading,
    error: tokenError,
  } = useTokenBalances(address, chainIdHex);
  const {
    nftBalances,
    loading: nftLoading,
    error: nftError,
  } = useNftBalances(address, chainIdHex);

  const burnItem = async () => {
    if (!selected || !address) return;
    setLoading(true);
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();

    if (selected.isToken) {
      await burnTokens(selected.address, selected?.balance, signer);
    } else if (selected.isERC721) {
      await burnERC721(selected.address, selected?.tokenId, signer);
    } else if (selected.isERC1155) {
      await burnERC1155(
        selected.address,
        selected?.tokenId,
        selected?.amount,
        signer
      );
    } else {
      toast.error("Invalid Itme");
    }
    setLoading(false);
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      setEnsName("");
      const result = await lookupEnsByAddress(
        searchAddress,
        activeChain.rpcUrl
      );
      setEnsName(result);
    } catch (e) {
      console.error("Failed to fetch ENS name");
    } finally {
      setLoading(false);
    }
  };

  const handleAppBox = () => {
    if (!address) return toast.error("Connect your wallet to use App");
    if (appbox) {
      setappbox(null);
    } else {
      setappbox(true);
    }
  };

  const handleAboutBox = () => {
    if(!aboutbox) {
      setaboutbox(true)
    } else {
      setaboutbox(null)
    }
  }

  const handleFeaturesNav = (nav_value) => {
    if (nav_value === "nft") {
      setshownfts(true);
      setshowdomians(null);
      setshowlp(null);
      setshowtokens(null);
    } else if (nav_value === "domain") {
      setshownfts(null);
      setshowdomians(true);
      setshowlp(null);
      setshowtokens(null);
    } else if (nav_value === "lp") {
      setshownfts(null);
      setshowdomians(null);
      setshowlp(true);
      setshowtokens(null);
    } else {
      setshownfts(null);
      setshowdomians(null);
      setshowlp(null);
      setshowtokens(true);
    }
  };

  return (
    <div className="">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnHover
        theme="light"
      />
      <div className={`app-box ${appbox ? "active" : ""}`}>
        <div className="about-header-box flex align-center">
          <div className="flex aha-dg position-relative">
            <div className="aha-line-box flex column justify-space-between left">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className="aha-text-box flex justify-center align-center">
              <p>Recycle bin</p>
            </div>
            <div className="aha-line-box flex column justify-space-between right">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div
              className="close-box flex justify-center align-center cursor-pointer"
              onClick={handleAppBox}
            >
              <img src={close_icon} alt="" />
            </div>
          </div>
        </div>

        <p className="app-box-title">
          Recycle bin: where the past meets the future of decentralized finance.
        </p>

        <div className="ft-nav-btn-cont flex row align-flex-end">
          <div
            className={`features-nav-btn cursor-pointer flex justify-center align-center ${
              shownfts ? "active" : null
            }`}
            onClick={() => handleFeaturesNav("nft")}
          >
            <p>NFTs</p>
          </div>
          <div
            className={`features-nav-btn cursor-pointer flex justify-center align-center ${
              showtokens ? "active" : null
            }`}
            onClick={() => handleFeaturesNav("token")}
          >
            <p>Tokens</p>
          </div>
          <div
            className={`features-nav-btn cursor-pointer flex justify-center align-center ${
              showdomains ? "active" : null
            }`}
            onClick={() => handleFeaturesNav("domain")}
          >
            <p>Domains</p>
          </div>
          <div
            className={`features-nav-btn cursor-pointer flex justify-center align-center ${
              showlp ? "active" : null
            }`}
            onClick={() => handleFeaturesNav("lp")}
          >
            <p>LP</p>
          </div>
        </div>

        <div className="app-features-container">
          <div className="app-features-box">
            {shownfts ? (
              <div className="grid grid-column-3 nft-items-box grid-column-gap-5 grid-row-gap-10">
                {nftBalances
                  ? nftBalances.map((nft) => (
                      <div className="fi-item-box">
                        <div className="fi-item-img-box">
                          <img src={getImageOrBrokenLink(nft.imageLink)} alt="" />
                        </div>
                        <h3>{nft.name}</h3>
                        <div className="flex justify-space-between">
                          <p>Floor</p>
                          <p>Symbol</p>
                        </div>
                        <div className="flex justify-space-between mgb-7">
                          <p>
                            {formatTokenAmount(nft.floor_price, nft.decimals) ||
                              0}{" "}
                            ETH
                          </p>
                          <p>{nft.symbol}</p>
                        </div>
                        <div className="flex justify-space-between align-center">
                          <div
                            className="burn-btn flex justify-center align-center cursor-pointer"
                            onClick={() => {
                              setwarnmodal(true);
                              setSelected({
                                address: nft.token_address,
                                isERC721: nft.contract_type === "ERC721",
                                tokenId: nft.token_id,
                                isERC1155: nft.contract_type === "ERC1155",
                                amount: nft.amount,
                              });
                            }}
                          >
                            <h5>🔥 Burn</h5>
                          </div>
                          {nft.possible_spam ? (
                            <div className="warn-icon-box">
                              <img src={warn_icon} className="" alt="" />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ))
                  : <p className="none_empt"> No NFTs </p>}
              </div>
            ) : null}

            {showtokens ? (
              <div className="grid grid-column-3 token-items-box grid-column-gap-5 grid-row-gap-10">
                {tokenBalances
                  ? tokenBalances.map((token) => (
                      <div className="fi-item-box token">
                        <div className="fi-item-img-box">
                          <img
                            src={getImageOrBrokenLink(
                              token.logo || token.thumbnail
                            )}
                            alt=""
                          />
                        </div>
                        <h3>{token.name}</h3>
                        <div className="flex justify-space-between align-center mgb-5">
                          <p>C/A</p>
                          <div className="ca-show flex justify-center align-center">
                            <h4>{formatAddress(token.token_address)}</h4>
                          </div>
                        </div>
                        <div className="flex justify-space-between mgb-7">
                          <p>Balance:</p>
                          <p>
                            {formatTokenAmount(token.balance, token.decimals)} $
                            {token.symbol}
                          </p>
                        </div>
                        <div className="flex row justify-space-between align-center">
                          <div
                            className="burn-btn flex justify-center align-center cursor-pointer"
                            onClick={() => {
                              setwarnmodal(true);
                              setSelected({
                                address: token.token_address,
                                isToken: true,
                                balance: token.balance,
                              });
                            }}
                          >
                            <h5>🔥 Burn</h5>
                          </div>
                          {token.possible_spam ? (
                            <div className="warn-icon-box">
                              <img src={warn_icon} className="" alt="" />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ))
                  : <p className="none_empt"> No Tokens </p>}
              </div>
            ) : null}

            {showdomains ? (
              <div className="domain-box">
                <div className="domain-box-inner">
                  <h3>Web3 Identity for Everyone</h3>

                  <div className="flex justify-center mgb-16">
                    <div className="flex row align-center gap-3 dm-search-box">
                      <img src={search_icon} alt="" />
                      <input
                        placeholder="Search Ens by wallet address"
                        value={searchAddress}
                        onChange={(e) => setSearchAddress(e.target.value)}
                      />
                    </div>
                  </div>
                  {ensName.length > 0 && searchAddress.length > 0 && (
                    <>
                      <div className="mgb-20">
                        <h4>Wallet Address</h4>
                        <div className="dm-address-box flex align-center">
                          <p>{searchAddress}</p>
                        </div>
                      </div>

                      <div className="">
                        <h4>Domains connected to this wallet</h4>
                        <div className="dm-address-box flex align-center">
                          <p>{ensName}</p>
                        </div>
                      </div>
                    </>
                  )}
                  <button
                    className="dm-address-box flex align-center"
                    onClick={handleSearch}
                    disabled={loading}
                    style={{
                      margin: "10px 0",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                  >
                    {loading ? "Searching..." : "Search ENS"}
                  </button>
                </div>
              </div>
            ) : null}

            {showlp ? (
              <div className="grid grid-column-3 lp-items-box grid-column-gap-5 grid-row-gap-10">
                {lpBalances
                  ? lpBalances.map((lp) => (
                      <div className="lp-item">
                        <div className="flex justify-center">
                          <div className="flex lp-icon-box">
                            <div className="lp-icon-box-item">
                              <img src={getImageOrBrokenLink(lp.logo)} alt="" />
                            </div>
                            <div className="lp-icon-box-item shift">
                              <img
                                src={getImageOrBrokenLink(lp.thumbnail)}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>

                        <h3>{lp.name}</h3>

                        <div className="flex justify-center">
                          <div className="lp-item-ca-show flex justify-center align-center">
                            <p>{formatAddress(lp.token_address)}</p>
                          </div>
                        </div>

                        <div className="flex justify-space-between">
                          <div
                            className="burn-btn flex justify-center align-center cursor-pointer"
                            onClick={() => {
                              setwarnmodal(true);
                              setSelected({
                                address: lp.token_address,
                                isToken: true,
                                balance: lp.balance,
                              });
                            }}
                          >
                            <h5>🔥 Burn</h5>
                          </div>
                          <a
                            href={`${activeChain.explorerUrl}address/${lp.token_address}`}
                            target="_blank"
                          >
                            <div className="burn-btn flex justify-center align-center cursor-pointer">
                              <h5>Explore</h5>
                            </div>
                          </a>
                        </div>
                      </div>
                    ))
                  : <p className="none_empt"> No LPs </p>}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className={`warn-container ${warnmodal ? "active" : ""}`}>
        <div className="about-header-box flex align-center">
          <div className="flex aha-dg position-relative">
            <div className="aha-line-box flex column justify-space-between left">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className="aha-text-box flex justify-center align-center">
              <p>Confirm</p>
            </div>
            <div className="aha-line-box flex column justify-space-between right">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div
              className="close-box flex justify-center align-center cursor-pointer"
              onClick={() => setwarnmodal(null)}
            >
              <img src={close_icon} alt="" />
            </div>
          </div>
        </div>

        <div className="warn-content">
          <div className="flex justify-center">
            <img src={warn_icon} alt="" />
          </div>
          <p>Are you sure you want to burn this token?</p>
          <div className="flex justify-space-between">
            <div
              className="burn-btn flex justify-center align-center cursor-pointer"
              onClick={() => setwarnmodal(null)}
            >
              <h5>Cancel</h5>
            </div>
            <div
              className="burn-btn flex justify-center align-center cursor-pointer"
              onClick={burnItem}
              disabled={loading}
            >
              <h5>{loading ? "Burning" : "Confirm"}</h5>
            </div>
          </div>
        </div>
      </div>

      <section id="home" className="home">
        <div className="home-header">
          <div className="flex justify-space-between align-center">

            <div className="flex row align-center gap-5 home-header-rcy-box">
              <img src={rcy_icon} alt="" />
              <p>Recycle Bin</p>
            </div>

            <div
              className="home-header-btn flex align-center justify-center cursor-pointer"
              onClick={
                !address ? open : () => open({ view: "Account" })
              }
            >
              <div className="flex row align-center">
                <div className="hh_btn_icon_box">
                  <img src={eth_icon} alt="" />
                </div>
                {address ? address.slice(0, 14) : <p>Connect wallet</p>}
              </div>
            </div>

          </div>
        </div>

        <div className="home-content-box flex row wrap justify-space-between align-flex-start">

          <div className="">

            {aboutbox ? <div className="about-box">
              <div className="about-header-box flex align-center">
                <div className="flex aha-dg position-relative">
                  <div className="aha-line-box flex column justify-space-between left">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="aha-text-box flex justify-center align-center">
                    <p>About Recycle Bin</p>
                  </div>
                  <div className="aha-line-box flex column justify-space-between right">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="close-box flex justify-center align-center cursor-pointer" onClick={handleAboutBox}>
                    <img src={close_icon} alt="" />
                  </div>
                </div>
              </div>

              <div className="about-info-cont">
                <div className="about-info-box flex row wrap align-flex-start gap-10">
                  <div className="about-info-icon">
                    <img src={rcy_icon} alt="" />
                  </div>

                  <div className="about-info-text">
                    <p>
                    Recycle Bin is a decentralized app built on the Ethereum network, inspired by the iconic design of Windows Vista. Blending nostalgia with cutting-edge blockchain technology, $BIN brings a unique and seamless user experience to the EVM world. <br />
                      <br />
                      Our dApp isn’t just about looks—it’s built for utility. With Vista, users can easily burn tokens, NFTs, and liquidity pool (LP) tokens, streamlining their asset management and enhancing scarcity with just a few clicks.


                    </p>
                    <h3>$BIN Contract Address</h3>
                    <div className="ca-box">
                      <h2>0x0564F324d2D96d2AeF51A871354A7e1b1c84243C</h2>
                    </div>
                    <div className="about-info-line"></div>
                    <p>Copyright 2024-2025 Recycle Bin Vista</p>
                    <div className="flex justify-flex-end">
                      <div className="connect-btn flex justify-center align-center cursor-pointer">
                        <div
                          className="flex row align-center"
                          onClick={
                            !address ? open : () => open({ view: "Account" })
                          }
                        >
                          <img src={eth_icon} alt="" />
                          {address ? address.slice(0, 14) : <p>Connect wallet</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> : null}
          </div>

          <div className="home-icon-cont flex column gap-60">
            <div className="icon-item flex align-center column cursor-pointer" /*onClick={handleAppBox}*/>
              <img src={rcy_icon} className="rcy" alt="" />
              <div className="icon-title-box flex justify-center align-center">
                <p>Recycle Bin</p>
              </div>
            </div>

            <a
              className="icon-item flex align-center column cursor-pointer"
              href="https://ethervista.app/"
              target="_blank"
            >
              <img src={vista_logo} className="vista" alt="" />
              <div className="icon-title-box flex justify-center align-center">
                <p>Vista App</p>
              </div>
            </a>

            <div className="icon-item flex align-center column cursor-pointer" onClick={handleAboutBox}>
              <img src={info_icon} className="info" alt="" />
              <div className="icon-title-box flex justify-center align-center">
                <p>About</p>
              </div>
            </div>
          </div>
          
        </div>

        {windowWidth < 500 ? <div className={`side-links-control flex justify-center align-center cursor-pointer ${sidelinks ? 'active' : ''}`} onClick={handleSideLinks}>
          <img src={angle_up_icon} alt="" />
        </div> : null}

        <div className={`side-links-box ${sidelinks ? 'active' : ''}`}>
          <a href="https://t.me/RecycleVista" target="_blank" className="flex justify-center align-center side-links-btn">
            <p>Telegram</p>
          </a>
          <a href="https://x.com/RecycleVista" target="_blank" className="flex justify-center align-center side-links-btn">
            <p>Twitter</p>
          </a>
          <a href="https://dexscreener.com/ethereum/0x19db15fde303a8aaf7808fd6803f90564886968a" target="_blank" className="flex justify-center align-center side-links-btn">
            <p>Chart</p>
          </a>
        </div>

        <div className="taskbar">
          <div className="flex justify-space-between align-center">

            <div className="flex row gap-5">

              <div className="tsk_bar_btn flex justify-center align-center cursor-pointer">
                  <div className="flex row gap-2 align-center">
                    <p>Start</p>
                  </div>
              </div>
              <div className="tsk_bar_btn flex justify-center align-center cursor-pointer" /*onClick={handleAppBox}*/>
                  <div className="flex row gap-2 align-center">
                    <div className="tsk_bar_icon_box">
                      <img src={rcy_icon} alt="" />
                    </div>
                    {windowWidth > 600 ? <p>Recycle bin</p> : null}
                  </div>
              </div>
              <a href="https://ethervista.app/" target="_blank" className="tsk_bar_btn flex justify-center align-center cursor-pointer">
                  <div className="flex row gap-2 align-center">
                    <div className="tsk_bar_icon_box">
                      <img src={vista_logo} alt="" />
                    </div>
                    {windowWidth > 600 ? <p>Vista</p> : null}
                  </div>
              </a>
              <div className="tsk_bar_btn flex justify-center align-center cursor-pointer" onClick={handleAboutBox}>
                  <div className="flex row gap-2 align-center">
                    <div className="tsk_bar_icon_box info_one">
                      <img src={info_icon} alt="" />
                    </div>
                    {windowWidth > 600 ? <p>About</p> : null}
                  </div>
              </div>

            </div>


            <div className="time-box">
              <p>{formatTime(currentTime)}</p>
            </div>


          </div>
        </div>

      </section>
    </div>
  );
};

export default Home;
